// Routing
import { useHistory } from 'react-router-dom'
// Algolia
import { algoliasearch } from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
// Global - Config
import { algoliaApiKey, algoliaApplicationId, algoliaIndex } from 'config'
// Local - Components
import { Autocomplete, ProductItem } from './components'

// Prepare a new Algolia Search Client
const searchClient = algoliasearch(algoliaApplicationId, algoliaApiKey)

const SearchLive: React.FC = () => {
  // Routing
  const history = useHistory()

  const getRedirectURL = (query) => {
    // Prepare our URL
    const urlWithParams = new URL('http://localhost.com/shop/')

    // Append the query to the URL
    urlWithParams.searchParams.append('query', query)

    // Get the search params from the current URL
    const searchParams = new URLSearchParams(location.search)

    // Get the marketplace from the current URL
    const marketplace = searchParams.get('marketplace')

    // If the marketplace is set, append it to the URL
    if (marketplace != null) {
      // Append the marketplace to the URL
      urlWithParams.searchParams.append('marketplace', marketplace)
    }

    // Get the order from the current URL
    const orderId = searchParams.get('order')

    // If the order is set, append it to the URL
    if (orderId != null) {
      // Append the order to the URL
      urlWithParams.searchParams.append('order', orderId)
    }

    console.log('Redirecting to', urlWithParams)

    // Get the supplier from the current URL
    return urlWithParams.href.replace('http://localhost.com', '')
  }

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(getRedirectURL(state.query))
        }
      }
    }
  })

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${algoliaIndex}_query_suggestions`,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(getRedirectURL(state.query))
        }
      }
    }
  })

  return (
    <>
      <Autocomplete
        debug={false}
        detachedMediaQuery=''
        getSources={({ query }) => [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: algoliaIndex,
                    params: {
                      query,
                      filters: 'showInStore:true AND isPrivateProduct:false'
                    }
                  }
                ]
              })
            },
            onSelect: (props) => {
              history.push(getRedirectURL(props.item.name))
            },
            templates: {
              item({ item, components }) {
                return <ProductItem components={components} hit={item} />
              }
            }
          }
        ]}
        insights={true}
        // onStateChange={({ state }) => {
        //   console.log('state', state)
        // }}
        onSubmit={({ state }) => {
          history.push(getRedirectURL(state.query))
        }}
        openOnFocus={true}
        placeholder='Search through products, categories and suppliers... '
        querySuggestionsPlugin={querySuggestionsPlugin}
        recentSearchesPlugin={recentSearchesPlugin}
      />
    </>
  )
}

export { SearchLive }
