import { LeafLogixTypes } from 'types'

export const LeafLogixTypeAsString = (type: LeafLogixTypes): string => {
    switch (type) {
        case LeafLogixTypes.Products:
            return 'Products'
        case LeafLogixTypes.Rooms:
            return 'Rooms'
        case LeafLogixTypes.Vendors:
            return 'Vendors'
        default:
            return ''
    }
} 