// React
import { createContext } from 'react'
// MainStem - API
import {
  MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse,
  MainStemSharpProvidersLeafLogixControllersProductListProductDetail
} from 'api-new'

export const AppContext = createContext({} as AppContextDetails)

export interface AppContextDetails {
  addMessages: (messages: Array<IMessage>) => void
  integrations: IAppContextIntegrations
  loggedInUser: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse
  messages: Array<IMessage>
  particlesInitiated: boolean
  permissions: any
  setIntegrations: React.Dispatch<React.SetStateAction<IAppContextIntegrations>>
  setLoggedInUser: React.Dispatch<
    React.SetStateAction<MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse | undefined>
  >
  setPermissions: React.Dispatch<React.SetStateAction<any | undefined>>
  setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>
  userToken: string
}

export type IMessage = {
  message: React.ReactNode
  type: 'user' | 'bot'
}

export interface IAppContextIntegrations {
  leafLogix?: IAppContextIntegrationsLeafLogix
}

export interface IAppContextIntegrationsLeafLogix {
  products: MainStemSharpProvidersLeafLogixControllersProductListProductDetail[]
}
