// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { Badge, SelectSingle, Spinner } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
import { SelectSingleValue } from '@mainstem/react-mainstem/dist/components/Form/Select/Single/types'
// MainStem - API
import { MainStemApi } from 'api-new'
// Global - Helpers
import { LeafLogixTypeAsString, leafLogixLoadProducts, loadLeafLogixRooms, loadLeafLogixVendors } from 'helpers'
// Global - Types
import { LeafLogixTypes } from 'types'
// Local - Types
import { ILeafLogixLookup } from './types'

const LeafLogixLookup = ({
  defaultValue = '',
  disabled = false,
  onSelected,
  setInitialLeafLogixID,
  options,
  type,
  customerIntegrationID,
  recordID
}: ILeafLogixLookup) => {
  // View State - Data
  const [localOptions, setLocalOptions] = useState<ISelectOption[]>(options ?? [])
  // View State - Inputs
  const [localDefaultOption, setLocalDefaultOption] = useState<undefined | SelectSingleValue>(undefined)
  // View State - Loading Indicators
  const [loadingFind, setLoadingFind] = useState<boolean>(false)
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false)

  const loadList = () => {
    if (!customerIntegrationID) {
      return
    }

    setLoadingOptions(true)

    switch (type) {
      case LeafLogixTypes.Products:
        return leafLogixLoadProducts(customerIntegrationID, setLoadingOptions, (options) => {
          console.log('loadList - Products', options)
          setLocalOptions(options)
        })
      case LeafLogixTypes.Rooms:
        return loadLeafLogixRooms(customerIntegrationID, setLoadingOptions, (options) => {
          setLocalOptions(options)
        })
      case LeafLogixTypes.Vendors:
        return loadLeafLogixVendors(customerIntegrationID, setLoadingOptions, (options) => {
          setLocalOptions(options)
        })
      default:
        return Promise.resolve([])
    }
  }

  const handleFindDefaultValue = (recordID: number, type: LeafLogixTypes, customerIntegrationID: number) => {
    if (!customerIntegrationID) return Promise.resolve(undefined)
    return MainStemApi.api
      .integrationLeafLogixGetByType({
        recordID: recordID,
        type: LeafLogixTypeAsString(type),
        customerIntegrationSettingID: customerIntegrationID
      })
      .then((response) => {
        const responseData = response.data
        return responseData ?? response
      })
  }

  // Fires when an item is selected in the list
  const handleSelected = (selectedOption: any) => {
    setLocalDefaultOption(selectedOption)
    if (onSelected) {
      if (selectedOption) {
        onSelected(selectedOption.value)
      } else {
        onSelected(undefined)
      }
    }
  }

  useEffect(() => {
    if (customerIntegrationID) {
      console.log('useEffect - Customer Integration ID', customerIntegrationID)
      loadList().then((listResponse) => {
        if (recordID && !defaultValue) {
          setLoadingFind(true)
          // If no default value exists & we have a recordID, try to find the default value
          handleFindDefaultValue(recordID, type, customerIntegrationID).then((response) => {
            const leafLogixLinkID = response?.selectionResult?.leafLogixLinkID
            if (onSelected) {
              onSelected(leafLogixLinkID)
            }
            if (setInitialLeafLogixID) {
              setInitialLeafLogixID(leafLogixLinkID)
            }
            const selectedOption = listResponse.find((option: any) => option.value == leafLogixLinkID)
            setLocalDefaultOption(selectedOption)
            setLoadingFind(false)
          })
        } else if (defaultValue && localOptions) {
          const selectedOption = localOptions.find((option: any) => option.value == defaultValue)
          setLocalDefaultOption(selectedOption)
        }
      })
    }
  }, [customerIntegrationID, recordID, defaultValue])

  const getTypeLabel = () => {
    switch (type) {
      case LeafLogixTypes.Products:
        return 'Product'
      case LeafLogixTypes.Rooms:
        return 'Room'
      case LeafLogixTypes.Vendors:
        return 'Vendor'
      default:
        return 'Unknown'
    }
  }

  return (
    <>
      {loadingOptions || loadingFind ? (
        <Spinner />
      ) : (
        <>
          <div>defaultValue:{defaultValue?.label}</div>
          {localOptions && localOptions.length > 0 ? (
            <SelectSingle
              disabled={disabled}
              onChange={handleSelected}
              options={localOptions}
              placeholder={`Select ${getTypeLabel()} in LeafLogix`}
              value={localDefaultOption}
            />
          ) : (
            <Badge color='danger'>Failed to connect to LeafLogix!</Badge>
          )}
        </>
      )}
    </>
  )
}

export { LeafLogixLookup }
