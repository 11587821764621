import React, { createContext, useContext, useState } from 'react';

interface FinancialDimensionCacheContextType {
    getCachedOptions: (financialDimensionID: number) => any[];
    setCachedOptions: (financialDimensionID: number, options: any[]) => void;
}

const FinancialDimensionCacheContext = createContext<FinancialDimensionCacheContextType | undefined>(undefined);

export const FinancialDimensionCacheProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cache, setCache] = useState<Record<number, any[]>>({});

    const getCachedOptions = (financialDimensionID: number) => {
        return cache[financialDimensionID] || [];
    };

    const setCachedOptions = (financialDimensionID: number, options: any[]) => {
        setCache(prev => ({
            ...prev,
            [financialDimensionID]: options
        }));
    };

    return (
        <FinancialDimensionCacheContext.Provider value={{ getCachedOptions, setCachedOptions }}>
            {children}
        </FinancialDimensionCacheContext.Provider>
    );
};

export const useFinancialDimensionCache = () => {
    const context = useContext(FinancialDimensionCacheContext);
    if (context === undefined) {
        throw new Error('useFinancialDimensionCache must be used within a FinancialDimensionCacheProvider');
    }
    return context;
}; 