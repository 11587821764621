// MainStem - API
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
import { Api, MainStemApi } from 'api-new'
// Global - Types
import { LeafLogixTypes } from 'types'

export const leafLogixLoadProducts = (
  customerIntegrationID: number,
  setLoading: (loading: boolean) => void,
  setter: (options: ISelectOption[]) => void
) => {
  return MainStemApi.api
    .integrationsLeafLogixProductsList({
      customerIntegrationSettingID: customerIntegrationID
    })
    .then((response) => {
      console.log('response', response)
      if (response.data) {
        const options = response.data.map(
          (product) =>
            ({
              label: product.productName,
              value: product.productId
            } as ISelectOption)
        )
        setter(options)
      }
      return response.data
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const loadLeafLogixRooms = (
  customerIntegrationID: number,
  setLoading: (loading: boolean) => void,
  setter: (options: any[]) => void
) => {
  return MainStemApi.api
    .integrationsLeafLogixRoomsList({
      customerIntegrationSettingID: customerIntegrationID
    })
    .then((response) => {
      if (response.data) {
        const options = response.data.map((room) => ({
          label: room.roomName,
          value: room.roomId
        }))
        setter(options)
      }
      return response.data
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const loadLeafLogixVendors = (
  customerIntegrationID: number,
  setLoading: (loading: boolean) => void,
  setter: (options: any[]) => void
) => {
  return MainStemApi.api
    .integrationsLeafLogixVendorsList({
      customerIntegrationSettingID: customerIntegrationID
    })
    .then((response) => {
      if (response.data) {
        const options = response.data.map((vendor) => ({
          label: vendor.vendorName,
          value: vendor.vendorId
        }))
        setter(options)
      }
      return response.data
    })
    .catch((error) => {
      return error
    })
    .finally(() => {
      setLoading(false)
    })
}

export const LeafLogixTypeAsString = (type: LeafLogixTypes): string => {
  switch (type) {
    case LeafLogixTypes.Products:
      return 'Product'
    case LeafLogixTypes.Rooms:
      return 'Room'
    case LeafLogixTypes.Vendors:
      return 'Vendor'
    default:
      return 'Unknown'
  }
}
